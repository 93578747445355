import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-lol/apis/api.mjs";
import { SERVICES_TO_REGIONS } from "@/game-lol/constants/constants.mjs";
import { SUPPORTED_QUEUES } from "@/game-lol/constants/season-review.mjs";
import LoLPlayerChampionSeasonStats from "@/game-lol/models/lol-player-champion-season-stats.mjs";
import { getLCUAvailable } from "@/game-lol/utils/lol-client-api.mjs";
import {
  extractRegionFromDerivedId,
  getDerivedId,
  separateDerivedRiotID,
} from "@/game-lol/utils/util.mjs";
import { devError } from "@/util/dev.mjs";

async function fetchData(params) {
  const [region, name, seasonStr] = params;

  if (!region || !name || !seasonStr) {
    devError("Missing region, name, or season.");
    return;
  }

  const derivedId = getDerivedId(region, name);
  const [gameName, tagLine] = separateDerivedRiotID(name);

  const currentSummoner = readState.volatile.currentSummoner;
  const currentRegion =
    currentSummoner && extractRegionFromDerivedId(currentSummoner);

  const isUnsupportedRegion = !SERVICES_TO_REGIONS[region.toLowerCase()];

  const useLCU =
    isUnsupportedRegion && currentRegion === region && getLCUAvailable();

  if (isUnsupportedRegion && !useLCU) {
    devError("Can not retrieve profile info by any means.");
    return;
  }

  const profile = await API.PLAYER.getProfile({ region, gameName, tagLine });

  if (!profile?.id || profile instanceof Error) return;

  const stats = SUPPORTED_QUEUES.map((queue) => {
    return getData(
      API.PLAYER.getPlayerChampionSeasonStats({
        region: region.toUpperCase(),
        leagueProfileId: profile.id,
        queue: queue.enum,
        season: Number(seasonStr),
      }),
      LoLPlayerChampionSeasonStats,
      [
        "lol",
        "playerChampionSeasonStats",
        seasonStr,
        queue.stateKey(derivedId),
      ],
      { shouldFetchIfPathExists: false },
    );
  });

  return Promise.all(stats);
}

export default fetchData;
